import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";

import HeaderRightTwo from "../Header-Right/HeaderRight-Two";
import Search from "../Offcanvas/Search";
import Category from "../Category/Category";
import Nav from "../Nav";
import Cookies from "js-cookie";
import logo from "../../../public/images/logo/logo.svg";
import logoLight from "../../../public/images/dark/logo/logo-light.png";
import { useAppContext } from "@/context/Context";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import GoogleLoginButton from "@/components/GoogleLoginButton";
import User from "@/components/Header/Offcanvas/User";

const HeaderEight = ({
  headerType,
  isUrl,
    mobile,
        setMobile,
  gapSpaceBetween,
  sticky,
  headerSticky,
  navigationEnd,
  container,
}) => {
  const [isSticky, setIsSticky] = useState(false);
 // const [isMounted, setIsMounted] = useState(false);
  const { isLightTheme } = useAppContext();
   let parsedUserInfo = null;
  const userInfo = Cookies.get('userInfo') || '{}';;
  parsedUserInfo = JSON.parse(userInfo);   

  useEffect(() => {
     
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // setIsMounted(true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`rbt-header-wrapper ${gapSpaceBetween} ${sticky}  ${
          !headerType && isSticky ? `${headerSticky}` : ""
        }`}
      >

        <div className={`${container}`}>
          <div className={`mainbar-row ${navigationEnd} align-items-center`}>
            <div className="header-left rbt-header-content">
              <div className="header-info">
                <div className="logo">
                  <Link href="/">
                    {isLightTheme ? (
                      <Image
                        src={logo}
                        width={152}
                        height={50}
                        priority={true}
                        alt="Education Logo Images"
                      />
                    ) : (
                      <Image
                        src={logoLight}
                        width={152}
                        height={50}
                        priority={true}
                        alt="Education Logo Images"
                      />
                    )}
                  </Link>
                </div>
              </div>

              {/* <div className="header-info d-none d-lg-block">
                <Category />
              </div> */}
            </div>

            <div className="rbt-main-navigation d-none d-xl-block">
              <Nav />
            </div>


            {!parsedUserInfo?.user ?(
                <>
                  <div className="header-right">
                    <div className="rbt-btn-wrapper d-none d-xl-block">
                      <Link className={`rbt-btn rbt-switch-btn btn-gradient btn-sm hover-transform-none`}
                            href="/signup">
                        <span data-text="Signup">Signup</span>
                      </Link>

                      <Link className={`rbt-btn mx-3 rbt-switch-btn btn-gradient btn-sm hover-transform-none`}
                            href="/login">
                        <span data-text="Login">Login</span>
                      </Link>
                    </div>
                    <li className="access-icon rbt-user-wrapper d-block d-xl-none">
                        <Link className="rbt-round-btn" href="/login">
                          <i className="feather-log-in"></i>
                        </Link>
                    </li>
                    <div className="mobile-menu-bar d-block d-xl-none">
                      <div className="hamberger">
                        <button
                            className="hamberger-button rbt-round-btn"
                            onClick={() => setMobile(!mobile)}
                        >
                          <i className="feather-menu"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </>
            ) : (
                <>
                  <HeaderRightTwo
                      userType={parsedUserInfo?.user?.firstName}
                      btnText="Enroll Now"
                      btnClass="rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none"
             />
                </>
                )
            }
          </div>
        </div>
        <Search />
      </div>
    </>
  );
};

export default HeaderEight;
