import Image from "next/image";
import UserData from "../../../data/user.json";
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux"; // Import useDispatch
import { logoutAction } from "@/redux/action/AuthAction";
import {toast} from "react-toastify";
import { googleLogout } from '@react-oauth/google';
import Cookies from "js-cookie";
const User = () => {
  const dispatch = useDispatch(); // Initialize useDispatch
  let parsedUserInfo = null;
  const userInfo = Cookies.get('userInfo') || '{}';;
  parsedUserInfo = JSON.parse(userInfo);  
  const handleLogout = () => {
    dispatch(logoutAction()); // Dispatch the logout action
    Cookies.remove("userInfo");
      if(parsedUserInfo?.user?.userType == 'google'){
        googleLogout();
      }
    toast.success('Logout successful', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

  };

  return (
    <div style={{marginLeft:"-50px"}} className="rbt-user-menu-list-wrapper userDerp">
      {parsedUserInfo?.user &&

          <div className="inner  ">
            <div className="rbt-admin-profile">
              {/*<div className="admin-thumbnail">*/}
              {/*  <Image*/}
              {/*    src={person.img}*/}
              {/*    width={43}*/}
              {/*    height={43}*/}
              {/*    alt="User Images"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="admin-info">
                {/*<span className="name">{userInfo.firstName}</span>*/}
                <Link
                  className="rbt-btn-link color-primary"
                  href="/instructor/instructor-profile"
                >
                  View Profile
                </Link>
              </div>
            </div>
            {/*{UserData && UserData.user.map((person, index) => (*/}
            {/*<ul className="user-list-wrapper" key={index}>*/}
            {/*  {person.userList.map((list, innerIndex) => (*/}
            {/*    <li key={innerIndex}>*/}
            {/*      <Link href={list.link}>*/}
            {/*        <i className={list.icon}></i>*/}
            {/*        <span>{list.text}</span>*/}
            {/*      </Link>*/}
            {/*    </li>*/}
            {/*  ))}*/}
            {/*</ul>*/}
            {/*    ))}*/}
            <hr className="mb--10" />
            <ul className="user-list-wrapper">
              <li>
                <Link href="/">
                  <i className="feather-book-open"></i>
                  <span>Getting Started</span>
                </Link>
              </li>
            </ul>
            <hr className="mt--10 mb--10" />
            <ul className="user-list-wrapper">
              <li>
                <Link href="/instructor/instructor-settings">
                  <i className="feather-settings"></i>
                  <span>Settings</span>
                </Link>
              </li>
              <li>
                <Link href="/login" onClick={handleLogout}> {/* Call handleLogout on click */}
                  <i className="feather-log-out"></i>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        // ))}
      }
    </div>
  );
};

export default User;
