const baseUrl3 = "https://api.breatheinwisdom.com/api/v1/program/user/public/"
const baseUrl1= "https://api.breatheinwisdom.com/api/v1/auth/"
const baseUrl2= "https://api.breatheinwisdom.com/api/v1/payment/"
const baseUrl4 = "https://api.breatheinwisdom.com/api/v1/video/"
const baseUrl5 = "https://api.breatheinwisdom.com/api/v1/tracking/user/"

// const baseUrl = "http://localhost:3012/api/v1/program/user/public/"
// const baseUrl1= "http://localhost:3012/api/v1/auth/"
// const baseUrl2= "http://localhost:4005/"
const baseUrl =  process.env.BASE_URL

const ApiUrl = {
  // auth
  CREATE_USER_URL: baseUrl1 + 'public/register',
  LOGIN_URL: baseUrl1 + 'public/login',
  USER_UPDATE: (id) => `${baseUrl1}private/users/${id}`,
  PASSWORD_UPDATE: (id) => `${baseUrl1}private/users/${id}/password-update`,
  SEND_CODE_URL: baseUrl1 + 'public/send/code/',
  VERIFY_CODE_URL: baseUrl1 + 'public/verify/',

  GET_CATEGORY_LIST: baseUrl + 'course_category',
  GET_CATEGORY: baseUrl + 'course_category',

  // Programs
  GET_COURSES_BY_CATEGORY: baseUrl3 + 'course_by_category',
  GET_ALL_PROGRAMS: baseUrl3 + 'all-courses',

  GET_ALL_COURSES: baseUrl3 + 'single-all-courses',
  UPDATE_COURSE: 'http://192.168.100.137:4000/stream/detail/2/2/2',
  DETAIL_COURSE: baseUrl3 + 'courses_details',
  ENROLLED_COURSES: baseUrl3 + 'enrolled-courses',
 
  // content
  GET_CONTENT_BY_COURSE: baseUrl3 + 'contents',
  GET_STREAM_URL: baseUrl4 + 'stream/detail',
  GET_VIDEO_HISTORY:baseUrl5+'video-progress',
  POST_VIDEO_HISTORY:baseUrl5+'track',
  GET_CONTENT_VIEW_DATA:baseUrl5+'progress',



  //offers
  GET_ALL_SUBSCRIPTIONS: baseUrl2 + 'public/subscriptions',

  // payment
  CREATE_CHECKOUT_SESSION: baseUrl2 + 'private/create-checkout-session',
  RECORD_PAYMENT_URL: baseUrl2 + 'private/record-payment',
  USER_ORDER_HISTORY: baseUrl2 + 'private/user-order-history',
  USER_ENROLLED_COURSES: baseUrl2 + 'private/user-enrolled-courses',
  GET_PROMOTION_TEXT: baseUrl2 + 'public/promotion-text',
  GET_PAYMENT_STATUS: baseUrl2 + 'private/user-enrolled-courses',


  GET_USER_PREREQUISITE_STATUS: baseUrl2 + 'private/user-prerequisite-status',

};
export default ApiUrl;
